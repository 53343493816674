<template>
  <v-dialog
    v-model="dialog"
    width="unset"
    class="preview-modal">
    <v-carousel
      hide-delimiters
      height="80vh">
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i">
        <img
          :src="item"
          class="preview-image"
          alt="preview-image">
      </v-carousel-item>
    </v-carousel>
    <!-- <v-card class="preview-image-card">
      <div class="preview-image-box">
        <img
          :src="imgSrc"
          class="preview-image"
          alt="preview-image">
      </div>
      <v-btn
        class="preview-image-close"
        elevation="5"
        icon
        @click="closeDialog()">
        <v-icon color="#a4a4a4">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card> -->
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.preview-image {
  height: 80vh;
  width: auto;
  max-width: auto !important;
  object-fit: contain;
}

.preview-modal {
  z-index: 10000;
}
</style>
